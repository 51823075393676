/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ol: "ol",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Baglietto to Introduce New Hybrid Propulsion System on Yachts from 2025"), "\n", React.createElement(_components.p, null, "According to recent announcements, Baglietto, the renowned Italian boat builder, is set to unveil a new hybrid propulsion system for its yachts starting in 2025. This innovative system, developed in collaboration with Siemens Energy and CGT, is designed to enhance efficiency and onboard comfort for users. The new hybrid propulsion system will be integrated into various Baglietto yacht models, including the T52 and DOM 133."), "\n", React.createElement(_components.h2, null, "Partnership with Siemens Energy and CGT"), "\n", React.createElement(_components.p, null, "Baglietto's partnership with Siemens Energy and CGT signifies a commitment to leveraging cutting-edge technology and solutions in the development of the hybrid propulsion system. While specific details about the system are currently limited, the involvement of these industry leaders suggests a focus on advanced and sustainable propulsion technologies."), "\n", React.createElement(_components.p, null, "For further information and updates, refer to the following sources:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.boatinternational.com/yachts/news/baglietto-hybrid-propulsion-yachts"
  }, "Baglietto to introduce new hybrid propulsion system on yachts from 2025 - Boat International")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.superyachttimes.com/yacht-news/baglietto-hybrid-propulsion-system-2025-yachts"
  }, "Baglietto to introduce new hybrid propulsion system on yachts from 2025 - SuperYacht Times")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://lulop.com/en_EN/post/show/290256/baglietto-introduces-new-hybri.html"
  }, "Baglietto introduces new hybrid propulsion system to be installed on T52 and DOM 133 yachts starting in 2025")), "\n"), "\n", React.createElement(_components.p, null, "Kindly note that the information provided is based on current available sources and may be subject to updates or changes in the future."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
